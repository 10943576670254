import {API, PageModel, Resource} from '@coveo/platform-client';
import {FacetCollectionConfigurationModel} from '../../interfaces';

export class FacetCollection extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/commerce/v2/configurations/facets/collections`;

    list(trackingId: string) {
        return this.api.get<PageModel<FacetCollectionConfigurationModel>>(
            this.buildPath(FacetCollection.baseUrl, {trackingId}),
        );
    }

    get(id: string) {
        return this.api.get<FacetCollectionConfigurationModel>(`${FacetCollection.baseUrl}/${id}`);
    }

    create(model: FacetCollectionConfigurationModel) {
        return this.api.post<FacetCollectionConfigurationModel>(FacetCollection.baseUrl, model);
    }

    delete(id: string) {
        return this.api.delete(`${FacetCollection.baseUrl}/${id}`);
    }

    update(id: string, model: FacetCollectionConfigurationModel) {
        return this.api.put<FacetCollectionConfigurationModel>(`${FacetCollection.baseUrl}/${id}`, model);
    }
}
