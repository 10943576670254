import {API, Resource} from '@coveo/platform-client';
import {PreviewResultModel, RecommendationSlotPreviewModel} from '../../interfaces';

export class RecommendationSlotPreview extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/commerce/v2/recommendations/preview`;

    preview(options: RecommendationSlotPreviewModel) {
        return this.api.post<PreviewResultModel>(RecommendationSlotPreview.baseUrl, options);
    }
}
