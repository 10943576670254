import {Grid, GridColProps} from '@components/mantine';
import {FunctionComponent, ReactNode} from 'react';

import classes from './SplitLayout.module.css';

export interface SplitLayoutRightProps extends Omit<GridColProps, 'span'> {
    /**
     * The content of the right column.
     */
    children: ReactNode;
}

export const SplitLayoutRight: FunctionComponent<SplitLayoutRightProps> = ({children, ...props}) => {
    if (!children) {
        return null;
    }

    return (
        <Grid.Col span={{base: 12, md: 6}} className={classes.rightColumn} {...props}>
            {children}
        </Grid.Col>
    );
};
