import {ColumnDef, createColumnHelper, Group, useTable} from '@components/mantine';
import {BasicSourceModel, Platform, useQuery} from '@core/api';
import {FunctionComponent} from 'react';
import {SourceIdentity} from '@components/resource-identity';
import {PrivilegeRow} from '../../PrivilegesTable.types';
import {GenericGranularPrivilegesTable} from '../GenericGranularPrivilegesTable';
import {Locales} from '../../../../strings/Locales';
import {GranularAccessLevelPickerOptions} from '../GranularAccessLevelPicker';

const columnHelper = createColumnHelper<BasicSourceModel>();

const columns: Array<ColumnDef<BasicSourceModel>> = [
    columnHelper.accessor('name', {
        header: Locales.format('PrivilegesTable.Sources.column.name.header'),
        cell: ({getValue}) => (
            <SourceIdentity source={{name: getValue()}}>
                <SourceIdentity.Name />
            </SourceIdentity>
        ),
        enableSorting: false,
    }),
    columnHelper.accessor('sourceType', {
        header: Locales.format('PrivilegesTable.Sources.column.sourceType.header'),
        cell: ({getValue}) => (
            <SourceIdentity source={{sourceType: getValue()}}>
                <Group gap="sm">
                    <SourceIdentity.TypeImage size="md" />
                    <SourceIdentity.TypeLabel size="sm" />
                </Group>
            </SourceIdentity>
        ),
        enableSorting: false,
    }),
];

const pushDocumentOptions: GranularAccessLevelPickerOptions = {
    disabled: {value: 'NONE', label: Locales.format('AccessLevelPicker.option.DENY')},
    enabled: {value: 'ALLOW', label: Locales.format('AccessLevelPicker.option.ALLOW')},
};

export const SourcesPrivilegesTable: FunctionComponent<PrivilegeRow> = (props) => {
    const store = useTable<BasicSourceModel>();
    const query = useQuery({
        queryKey: ['basic-source', 'list'],
        queryFn: async () => {
            const response = await Platform.sourcesInternal.listAll();
            store.setTotalEntries(response.length);
            return response;
        },
    });
    const selectOptions = props.targetDomain === 'PUSH_DOCUMENT' ? pushDocumentOptions : undefined;
    return (
        <GenericGranularPrivilegesTable<BasicSourceModel>
            store={store}
            columns={columns}
            query={query}
            resourceName={props.name.toLowerCase()}
            options={selectOptions}
            {...props}
        />
    );
};
