const toKebabCase = (str: string): string =>
    str
        .replace(/([a-z])([A-Z])/g, '$1-$2') // Add a hyphen between camelCase words
        .replace(/[\s_]+/g, '-') // Replace spaces and underscores with hyphens
        .toLowerCase(); // Convert to lowercase

const ensureLeadingSlash = (str: string): string => (str.startsWith('/') ? str : `/${str}`);

const normalizePath = (path: string): string =>
    ensureLeadingSlash(path.split('/').filter(Boolean).map(toKebabCase).join('/'));

const buildPath = (verb: 'add' | 'edit' | 'view', basepath: string, subpath?: string) => {
    const parts = [basepath, verb];
    if (verb === 'edit' || verb === 'view') {
        parts.push(':id');
    }
    if (subpath) {
        parts.push(subpath);
    }
    return normalizePath(parts.join('/'));
};

export const PathsBuilder = {
    /**
     * Builds a set of paths for a resource with the given name.
     * Use it in conjunction with the generatePaths utility.
     * @param name Name of the resource (use PascalCase)
     * @param pathName The base path for the resource
     *
     * @example
     * ```ts
     * const MyPaths = generatePaths({
     *    ...PathsBuilder.resource('Fruits', '/fruits'),
     * });
     *
     * MyPaths.AddFruits(); // '/:orgId/fruits/add'
     * MyPaths.EditFruits({id: 'banana'}); // '/:orgId/fruits/edit/banana'
     * MyPaths.ViewFruits({id: 'banana'}); // '/:orgId/fruits/view/banana'
     * ```
     */
    resource: <Name extends string>(name: Name, pathName: string) => {
        type Entries = `Add${typeof name}` | `Edit${typeof name}` | `View${typeof name}`;
        return {
            [`Add${name}`]: buildPath('add', pathName),
            [`Edit${name}`]: buildPath('edit', pathName),
            [`View${name}`]: buildPath('view', pathName),
        } as Record<Entries, string>;
    },
};
