import {type PrivilegeModel} from '@core/api';
import {ArraysOfObjects, PrivilegesConstants} from '@core/user';
import isEqual from 'fast-deep-equal';
import {Locales} from '../../strings/Locales';
import {PrivilegesConfig, PrivilegesTableWarningProps, type PrivilegeRow} from './PrivilegesTable.types';
import {dependentPrivilegesMap, granularPrivilegesIds, noAccess} from './PrivilegesTableConstants';

/**
 * Converts to SCREAM_CASE
 */
const scream = (...parts: string[]) =>
    parts
        .filter(Boolean)
        .map((s) => s.toUpperCase())
        .join('_');

const getRowId = (privilege: PrivilegeModel): string =>
    scream(privilege.targetDomain, privilege.owner, privilege.level);

const getPrivilegeName = (privilege: PrivilegeModel): string =>
    Locales.formatOrHumanize(`PrivilegesRows.${privilege.targetDomain}`, {
        defaultTranslation: privilege.targetDomain,
    });

const getPrivilegeTabName = (privilege: PrivilegeModel): string =>
    Locales.formatOrDefault(`PrivilegesTabs.${getRowId(privilege)}`, {
        defaultTranslation: Locales.formatOrHumanize(`PrivilegesTabs.${scream(privilege.owner, privilege.level)}`, {
            defaultTranslation: privilege.owner,
        }),
    });

const getRowsFromPrivileges = ({
    systemPrivileges = noAccess,
    availablePrivileges = noAccess,
    initialPrivileges = noAccess,
    exclusivePrivileges = noAccess,
}: Omit<PrivilegesConfig, 'selectedPrivileges'>): PrivilegeRow[] => {
    const availablePrivilegesById = groupPrivilegesByRowId(availablePrivileges);
    const initialPrivilegesById = groupPrivilegesByRowId(initialPrivileges);
    const exclusivePrivilegesById = groupPrivilegesByRowId(exclusivePrivileges);
    return Object.values(
        systemPrivileges.reduce<Record<string, PrivilegeRow>>((rowsById, privilege): Record<string, PrivilegeRow> => {
            const rowId = getRowId(privilege);
            if (!rowsById[rowId]) {
                rowsById[rowId] = {
                    id: rowId,
                    name: getPrivilegeName(privilege),
                    tab: getPrivilegeTabName(privilege),
                    owner: privilege.owner,
                    targetDomain: privilege.targetDomain,
                    level: privilege.level,
                    systemPrivileges: [privilege],
                    availablePrivileges: availablePrivilegesById[rowId] || noAccess,
                    exclusivePrivileges: exclusivePrivilegesById[rowId] || noAccess,
                    initialPrivileges: initialPrivilegesById[rowId] || noAccess,
                };
            } else {
                rowsById[rowId].systemPrivileges.push(privilege);
            }
            return rowsById;
        }, {}),
    );
};

const getTabsFromPrivileges = (privileges: PrivilegeModel[]): string[] =>
    privileges ? Array.from(new Set(privileges.map(getPrivilegeTabName))).sort() : [];

const groupPrivilegesByRowId = (privileges: PrivilegeModel[]): Record<string, PrivilegeModel[]> =>
    privileges?.reduce<Record<string, PrivilegeModel[]>>((privilegesByRowId, privilege) => {
        const rowId = getRowId(privilege);
        if (!privilegesByRowId[rowId]) {
            privilegesByRowId[rowId] = [privilege];
        } else {
            privilegesByRowId[rowId].push(privilege);
        }
        return privilegesByRowId;
    }, {}) ?? {};

const isGranularPrivilegeId = (id: string): boolean =>
    granularPrivilegesIds.includes(id as (typeof granularPrivilegesIds)[number]);

const removeUndefinedProperties = <T extends object>(obj: T): T =>
    Object.keys(obj).reduce((acc, key) => {
        if (obj[key] !== undefined) {
            acc[key] = obj[key];
        }
        return acc;
    }, {} as T);

const isCreatePrivilege = (privilege: PrivilegeModel) => privilege.type === 'CREATE';

const hasChanged = (initial: PrivilegeModel[], selected: PrivilegeModel[]) => !isEqual(initial, selected);

const getWarnings = ({
    selectedPrivileges,
    initialPrivileges,
    availablePrivileges,
    exclusivePrivileges,
}: Omit<PrivilegesConfig, 'systemPrivileges'>): PrivilegesTableWarningProps[] => {
    const warnings: PrivilegesTableWarningProps[] = [];

    if (ArraysOfObjects.intersection(selectedPrivileges, PrivilegesConstants.potentPrivileges).length > 0) {
        warnings.push({
            type: 'POTENT',
            affectedPrivileges: selectedPrivileges,
        });
    }

    /**
     * Privileges that would cause the user to lock himself out if he saves the current selection.
     */
    const unselectedExclusivePrivileges = ArraysOfObjects.difference(exclusivePrivileges, selectedPrivileges);
    if (unselectedExclusivePrivileges.length > 0) {
        warnings.push({
            type: 'LOCKOUT',
            affectedPrivileges: unselectedExclusivePrivileges,
        });
    }

    /**
     * Privileges that will be lost if the user saves the current selection.
     * Those privileges were already set on the resource but are not available for the user and were removed from the selection.
     * By lost we mean that the user will not be able to set them back himself.
     */
    const privilegesLostIfSaved = ArraysOfObjects.difference(
        initialPrivileges,
        availablePrivileges,
        selectedPrivileges,
    );
    if (privilegesLostIfSaved.length > 0) {
        warnings.push({
            type: 'REDUCTION',
            affectedPrivileges: privilegesLostIfSaved,
        });
    }

    return warnings;
};

/**
 * Check selected privileges and automatatically enable dependent privileges if permission allows
 * @param selectedPrivileges current selected privileges
 * @param availablePrivileges privileges user has permission to set
 * @returns
 */
const getDependentPrivileges = (selectedPrivileges: PrivilegeModel[], availablePrivileges: PrivilegeModel[]) => {
    const result = [...selectedPrivileges];

    for (const privilege of selectedPrivileges) {
        const dependentPrivileges = dependentPrivilegesMap[privilege.targetDomain];
        if (dependentPrivileges) {
            for (const dependentPrivilege of dependentPrivileges) {
                const validPrivilege = availablePrivileges.find(
                    (p) =>
                        p.type === dependentPrivilege.type &&
                        p.targetDomain === dependentPrivilege.targetDomain &&
                        p.targetId === dependentPrivilege.targetId,
                );
                if (validPrivilege && !result.includes(validPrivilege)) {
                    result.push(validPrivilege);
                }
            }
        }
    }

    return result;
};

export const PrivilegesTableUtils = {
    getRowsFromPrivileges,
    getTabsFromPrivileges,
    groupPrivilegesByRowId,
    isGranularPrivilegeId,
    removeUndefinedProperties,
    isCreatePrivilege,
    hasChanged,
    getWarnings,
    getRowId,
    getDependentPrivileges,
};
