export const sanitizeOrganizationId = (orgId: string | null | undefined): string | undefined => {
    if (!orgId) {
        return undefined;
    }

    return orgId.replace(/[^a-zA-Z0-9-]/g, '');
};

export const sanitizeHostedSearchPageId = (searchPageId: string | null | undefined): string | undefined => {
    if (!searchPageId) {
        return undefined;
    }

    return searchPageId.replace(/[^a-zA-Z0-9\-]/g, '');
};

export const sanitizeClassicHostedSearchPageName = (searchPageName: string | null | undefined): string | undefined => {
    if (!searchPageName) {
        return undefined;
    }

    return searchPageName.replace(/[^a-zA-Z0-9\-_]/g, '');
};

export const sanitizeRegion = (region: string | null | undefined): string | undefined => {
    if (!region) {
        return undefined;
    }

    return region.replace(/[^a-zA-Z]/g, '');
};
