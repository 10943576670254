import {API, PageModel, Resource} from '@coveo/platform-client';
import {FacetsFieldModel, FacetsFieldsOptions} from '../../interfaces';

export class Facets extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/commerce/v2/facets`;

    getFields({sortDirection = 'asc', name = '', ...options}: FacetsFieldsOptions) {
        return this.api.get<PageModel<FacetsFieldModel>>(
            this.buildPath(`${Facets.baseUrl}/fields`, {...options, name, sortDirection}),
        );
    }
}
