import {Platform, PrivilegeModel} from '@core/api';
import {BasePayload, IDispatch, IReduxAction, IThunkAction} from '@coveord/plasma-react';

import {PrivilegesLists, privilegesListsIds} from '../constants/PrivilegesConstants';

export interface PrivilegesListPayload extends BasePayload {
    privileges: PrivilegeModel[];
}

export const PrivilegesListActionTypes = {
    set: 'SET_PRIVILEGES_LIST',
    remove: 'REMOVE_PRIVILEGES_LIST',
    addPrivilege: 'ADD_PRIVILEGES_TO_LIST',
    removePrivilege: 'REMOVE_PRIVILEGES_FROM_LIST',
};

const privilegesListsUrls = {
    [PrivilegesLists.Groups]: () => Platform.organization.listPrivileges(),
    [PrivilegesLists.GlobalGroups]: () => Platform.privileges.getGlobalGroupPrivileges(),
    [PrivilegesLists.ApiKeys]: () => Platform.organization.listApiKeysPrivileges(),
    [PrivilegesLists.User]: () => Platform.organization.listMyPrivileges(),
};

const addOrOverwrite = (id: string, privileges: PrivilegeModel[]): IReduxAction<PrivilegesListPayload> => ({
    type: PrivilegesListActionTypes.set,
    payload: {
        id,
        privileges,
    },
});

const remove = (id: string): IReduxAction<BasePayload> => ({
    type: PrivilegesListActionTypes.remove,
    payload: {id},
});

const addToList = (id: string, privileges: PrivilegeModel[]): IReduxAction<PrivilegesListPayload> => ({
    type: PrivilegesListActionTypes.addPrivilege,
    payload: {
        id,
        privileges,
    },
});

const removeFromList = (id: string, privileges: PrivilegeModel[]): IReduxAction<PrivilegesListPayload> => ({
    type: PrivilegesListActionTypes.removePrivilege,
    payload: {
        id,
        privileges,
    },
});

export interface FetchPrivilegesListOptions {
    list: PrivilegesLists;
    id?: string;
    done?: () => void;
    fail?: (...args: any[]) => any;
}

const fetchPrivileges =
    ({list, id, done, fail}: FetchPrivilegesListOptions): IThunkAction =>
    async (dispatch: IDispatch) => {
        const listId = privilegesListsIds[list] || id;
        try {
            const data: PrivilegeModel[] = id
                ? await privilegesListsUrls[PrivilegesLists.Groups]()
                : await privilegesListsUrls[list]();
            dispatch(addOrOverwrite(listId, data));
            done?.();
        } catch (error) {
            fail?.(error);
        }
    };

export const PrivilegesListActions = {
    addOrOverwrite,
    remove,
    fetchPrivileges,
    addToList,
    removeFromList,
};
