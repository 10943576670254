import {SourceType} from '@coveo/platform-client';

/**
 * The category of a source.
 *
 * @description Replace PlatformClient's SourceCategory type with this one to add the ON_PREM category not supported by our APIs.
 *
 * @experimental do not move to PlatformClient
 */
export enum SourceCategory {
    CLOUD = 'CLOUD',
    CRAWLING_MODULE = 'CRAWLING_MODULE',
    ON_PREM = 'ON_PREM',
    PUSH = 'PUSH',
}

export interface BasicSourceModel {
    /**
     * The unique identifier of the source.
     */
    id: string;
    /**
     * The name of the source.
     */
    name: string;
    /**
     * The type of the source.
     */
    sourceType: SourceType;
}
