import {ColumnDef, EmptyState, Paper, Table, TableStore} from '@components/mantine';
import {UseQueryResult} from '@core/api';
import {useMemo} from 'react';
import {Locales} from '../../../strings/Locales';
import {PrivilegeRow} from '../PrivilegesTable.types';
import {GranularAccessLevelPicker, GranularAccessLevelPickerOptions} from './GranularAccessLevelPicker';
import classes from './GranularPrivilegesTable.module.css';

interface GenericGranularPrivilegesTableProps<T> extends PrivilegeRow {
    columns: Array<ColumnDef<T>>;
    query: UseQueryResult<T[], Error>;
    store: TableStore<T>;
    resourceName: string;
    children?: React.ReactNode;
    options?: GranularAccessLevelPickerOptions;
}

export const GenericGranularPrivilegesTable = <T extends {id: string}>({
    columns: resourceSpecificColumns,
    resourceName,
    query,
    store,
    owner,
    targetDomain,
    id,
    children,
    options,
}: GenericGranularPrivilegesTableProps<T>) => {
    const columns: Array<ColumnDef<T>> = useMemo(
        () => [
            ...resourceSpecificColumns,
            {
                id: 'granular-privilege-picker',
                header: Locales.format('PrivilegesTable.column.access.header'),
                cell: ({row}) => (
                    <GranularAccessLevelPicker
                        owner={owner}
                        targetDomain={targetDomain}
                        targetId={row.original.id}
                        options={options}
                    />
                ),
            },
        ],
        [resourceSpecificColumns],
    );
    return (
        <Paper withBorder radius="md" className={classes.root}>
            <Table
                store={store}
                data={query.data}
                loading={query.isLoading}
                columns={columns}
                data-testid={`${id}-granular-privileges-table`}
            >
                <Table.NoData>
                    <EmptyState variant="feedback">
                        <EmptyState.Title>
                            {Locales.format('PrivilegesTable.granularResourceTable.emptyState.title', {
                                resourceName,
                            })}
                        </EmptyState.Title>
                        <EmptyState.Image />
                        <EmptyState.Body>
                            {Locales.format('PrivilegesTable.granularResourceTable.emptyState.body', {
                                resourceName,
                            })}
                        </EmptyState.Body>
                    </EmptyState>
                </Table.NoData>
                {children}
            </Table>
        </Paper>
    );
};
