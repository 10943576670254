import {ColumnDef, createColumnHelper, useTable} from '@components/mantine';
import {SecurityProviderModel, Platform, useQuery} from '@core/api';
import {FunctionComponent} from 'react';
import {PrivilegeRow} from '../../PrivilegesTable.types';
import {GenericGranularPrivilegesTable} from '../GenericGranularPrivilegesTable';
import {Locales} from '../../../../strings/Locales';
import {GranularAccessLevelPickerOptions} from '../GranularAccessLevelPicker';

const columnHelper = createColumnHelper<SecurityProviderModel>();

const columns: Array<ColumnDef<SecurityProviderModel>> = [
    columnHelper.accessor('name', {
        header: Locales.format('PrivilegesTable.SecurityProviders.column.name.header'),
        enableSorting: false,
    }),
];

const selectOptions: GranularAccessLevelPickerOptions = {
    disabled: {value: 'NONE', label: Locales.format('AccessLevelPicker.option.DENY')},
    enabled: {value: 'ALLOW', label: Locales.format('AccessLevelPicker.option.ALLOW')},
};

export const SecurityProvidersPushPrivilegesTable: FunctionComponent<PrivilegeRow> = (props) => {
    const store = useTable<SecurityProviderModel>();
    const query = useQuery({
        queryKey: ['securityCache', 'listProviders'],
        queryFn: async () => {
            const response = await Platform.securityCache.listProviders();
            store.setTotalEntries(response.length);
            return response;
        },
    });
    return (
        <GenericGranularPrivilegesTable<SecurityProviderModel>
            store={store}
            columns={columns}
            query={query}
            resourceName={props.name.toLowerCase()}
            options={selectOptions}
            {...props}
        />
    );
};
