import {ComboboxItem, Select} from '@components/mantine';
import {FunctionComponent, useMemo} from 'react';
import {Locales} from '../../../strings/Locales';
import {usePrivilegeRow} from '../PrivilegeTableContext';
import {PrivilegesTableUtils} from '../PrivilegesTableUtils';

export type GranularAccessLevelPickerOptions = Record<'disabled' | 'enabled', ComboboxItem>;

export interface GranularAccessLevelPickerProps {
    owner: string;
    targetDomain: string;
    targetId: string;
    options: GranularAccessLevelPickerOptions;
}

const defaultOptions: GranularAccessLevelPickerOptions = {
    disabled: {value: 'VIEW', label: Locales.format('AccessLevelPicker.option.VIEW')},
    enabled: {value: 'EDIT', label: Locales.format('AccessLevelPicker.option.EDIT')},
};

export const GranularAccessLevelPicker: FunctionComponent<GranularAccessLevelPickerProps> = ({
    owner,
    targetDomain,
    targetId,
    options = defaultOptions,
}) => {
    const id = PrivilegesTableUtils.getRowId({owner, targetDomain, targetId});
    const {onChange, selectedPrivileges, readOnly} = usePrivilegeRow(id);

    const data = useMemo(() => [options.disabled, options.enabled], [options]);

    const value = selectedPrivileges.some((privilege) => privilege.targetId === targetId)
        ? options.enabled.value
        : options.disabled.value;

    return (
        <Select
            data={data}
            value={value}
            w={148}
            readOnly={readOnly}
            onChange={(newValue) => {
                if (newValue === options.disabled.value) {
                    onChange(selectedPrivileges.filter((privilege) => privilege.targetId !== targetId));
                } else {
                    // special case for 'ALLOW' privilege the type should not exist as the backend expects it
                    const privilege =
                        options.enabled.value === 'ALLOW'
                            ? {targetId, owner, targetDomain}
                            : {type: options.enabled.value, targetId, owner, targetDomain};
                    onChange([...selectedPrivileges, privilege]);
                }
            }}
        />
    );
};
