import {Resource} from '@coveo/platform-client';
import {
    NotificationEventRequestModel,
    MultipleOrganizationsNotificationEventRequestModel,
    MultipleOrganizationsNotificationEventRequestParams,
} from '../interfaces';

export class NotificationEventResource extends Resource {
    static baseUrl = `/rest/notifications/events/send`;

    /**
     * Send a notification event to an organization
     * @param organizationId The organization ID to send to.
     * @param requestModel The request model.
     * @returns void
     */
    send(organizationId: string, requestModel: NotificationEventRequestModel) {
        return this.api.post<void>(
            this.buildPath(`${NotificationEventResource.baseUrl}/${organizationId}`, {}),
            requestModel,
        );
    }

    /**
     * Send a notification event to multiple organizations
     * @param requestModel The request model.
     * @param params The options.
     * @returns void
     */
    sendMultiple(
        requestModel: MultipleOrganizationsNotificationEventRequestModel,
        params: MultipleOrganizationsNotificationEventRequestParams,
    ) {
        return this.api.post<void>(this.buildPath(NotificationEventResource.baseUrl, params), requestModel);
    }
}
