import {Dictionary, Locales as LocalesGenerator} from '@core/locales';

import {LocalesKeys} from '../generated/LocalesKeys';
import AccessLevelPicker from './AccessLevelPicker.json';
import AccessTable from './AccessTable.json';
import ApiKeyCopyValuePrompt from './ApiKeyCopyValuePrompt.json';
import APIKeysAccessTable from './APIKeysAccessTable.json';
import GroupsAccessTable from './GroupsAccessTable.json';
import PrivilegesRows from './PrivilegesRows.json';
import PrivilegesTable from './PrivilegesTable.json';
import PrivilegesTabs from './PrivilegesTabs.json';

const req: Record<string, Record<string, any>> = {
    AccessLevelPicker,
    AccessTable,
    ApiKeyCopyValuePrompt,
    APIKeysAccessTable,
    GroupsAccessTable,
    PrivilegesRows,
    PrivilegesTable,
    PrivilegesTabs,
};
let translations = {};

Object.keys(req).forEach((key) => {
    const parts: string[] = key.split('/');
    const roots: string[] = parts.splice(0, parts.length - 2);

    let map: any = translations;

    roots.forEach((root: string) => {
        if (!map[root]) {
            map = {...map, [root]: {}};
        }
        map = map[root];
    });

    translations = {...map, ...req[key]};
});

const enDictionary: Dictionary = {
    locale: 'en',
    translations,
};

export const Locales: LocalesGenerator<LocalesKeys> = new LocalesGenerator<LocalesKeys>({
    dictionaries: [enDictionary],
    defaultLocale: 'en',
});
