import {FunctionComponent} from 'react';
import {PrivilegeRow} from '../PrivilegesTable.types';
import {PrivilegesTableUtils} from '../PrivilegesTableUtils';
import {usePrivilegeRow} from '../PrivilegeTableContext';
import {
    ApiKeysPrivilegesTable,
    ExtensionsPrivilegesTable,
    GroupsPrivilegesTable,
    SecurityProvidersPushPrivilegesTable,
    QueryPipelinesPrivilegesTable,
    SourcesPrivilegesTable,
} from './resources';

export const GranularPrivilegesTable: FunctionComponent<PrivilegeRow> = (row) => {
    const {customSelected} = usePrivilegeRow(row.id);
    if (!customSelected || !PrivilegesTableUtils.isGranularPrivilegeId(row.id)) {
        return null;
    }
    switch (row.id) {
        case 'SOURCE_PLATFORM':
        case 'PUSH_DOCUMENT_PLATFORM':
            return <SourcesPrivilegesTable {...row} />;
        case 'GROUP_PLATFORM':
            return <GroupsPrivilegesTable {...row} />;
        case 'API_KEY_PLATFORM':
            return <ApiKeysPrivilegesTable {...row} />;
        case 'INDEXING_PIPELINE_EXTENSION_PLATFORM':
            return <ExtensionsPrivilegesTable {...row} />;
        case 'QUERY_PIPELINE_SEARCH_API':
            return <QueryPipelinesPrivilegesTable {...row} />;
        case 'PUSH_IDENTITY_PLATFORM':
            return <SecurityProvidersPushPrivilegesTable {...row} />;
        default:
            return <div>Not implemented yet.</div>;
    }
};
