import {Resource} from '@coveo/platform-client';
import {OrganizationDefinitionResponse} from '../interfaces/OrganizationDefinitionInterfaces';

export class OrganizationDefinitionResource extends Resource {
    static baseUrl = '/rest/organizations';

    /**
     * Get the definition of a org.
     */
    getDefinition() {
        return this.api.get<OrganizationDefinitionResponse>(
            super.buildPath(`${OrganizationDefinitionResource.baseUrl}/${this.api.organizationId}/definition`),
        );
    }
}
