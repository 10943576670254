import {Platform, PrivilegeModel, useQueries, useQuery, UseQueryResult, UserModel} from '@core/api';
import {OrganizationSelectors} from '@core/organization';
import Registry from '@core/registry';
import {createContext, useCallback, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PrivilegesListActions, PrivilegesLists, privilegesListsIds} from '../privileges';
import {updateUser, UserPrivilegesValidator} from '../user';

export interface UserContextType extends UserModel {
    privileges: PrivilegeModel[];
    access: UserPrivilegesValidator;
}

export const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUser = (): UserContextType => {
    const context = useContext(UserContext);

    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }

    return context;
};

/**
 * Provides the user context to its children.
 * It uses the access token to retrieve all information about the user and their privileges.
 *
 * Constraints:
 * * has to be within context of the Redux Store provider.
 * * has to be within context of the QueryClientProvider.
 */
export const UserProvider = ({children}) => {
    const dispatch = useDispatch();

    // TODO replace with organization provider hook (https://coveord.atlassian.net/browse/ADUI-10435)
    const organization = useSelector(OrganizationSelectors.getOrganization);

    const {isPending: isPlatformInitializing} = useQuery({
        queryKey: ['init', 'check-token'],

        queryFn: async () => {
            await Platform.initialize();
            return 'valid';
        },
    });

    const combineQueries = useCallback(
        ([userQuery, userPrivilegesQuery]: [
            UseQueryResult<UserModel, Error>,
            UseQueryResult<PrivilegeModel[], Error>,
        ]) => ({
            pending: userQuery.isPending || userPrivilegesQuery.isPending,
            data:
                userQuery.status === 'success' && userPrivilegesQuery.status === 'success'
                    ? {
                          ...userQuery.data,
                          privileges: userPrivilegesQuery.data,
                          access: new UserPrivilegesValidator(userPrivilegesQuery.data, organization),
                      }
                    : undefined,
        }),
        [organization],
    );

    const userQueries = useQueries({
        queries: [
            {
                queryKey: ['init', 'user'],
                queryFn: async () => {
                    const user = await Platform.user.get();
                    dispatch(updateUser(user));

                    // Register the user model in the registry for tracking "Identify" function
                    Registry.register('userModel', user);
                    return user;
                },
                enabled: !isPlatformInitializing,
            },
            {
                queryKey: ['init', 'user-privileges'],
                queryFn: async () => {
                    const privileges = await Platform.privileges.getUserPrivileges();
                    dispatch(
                        PrivilegesListActions.addOrOverwrite(privilegesListsIds[PrivilegesLists.User], privileges),
                    );
                    return privileges;
                },
                enabled: !isPlatformInitializing,
            },
        ],
        combine: combineQueries,
    });

    return userQueries.pending || !userQueries.data ? null : (
        <UserContext.Provider value={userQueries.data}>{children}</UserContext.Provider>
    );
};
