import {Grid, GridColProps} from '@components/mantine';
import {FunctionComponent, ReactNode} from 'react';

export interface SplitLayoutLeftProps extends Omit<GridColProps, 'span'> {
    /**
     * The content of the left column.
     */
    children: ReactNode;
}

export const SplitLayoutLeft: FunctionComponent<SplitLayoutLeftProps> = ({children, ...props}) => (
    <Grid.Col span={{base: 12, md: 6}} {...props}>
        {children}
    </Grid.Col>
);
