import {
    AnchorCssVariables,
    AnchorProps,
    AnchorStylesNames,
    AnchorVariant,
    polymorphicFactory,
    PolymorphicFactory,
    StylesApiProps,
    useProps,
} from '@coveord/plasma-mantine';
import {ExternalSize16Px} from '@coveord/plasma-react-icons';
import {clsx} from 'clsx';
import {ReactNode} from 'react';
import {Anchor} from '../anchor';
import classes from './ExternalAnchor.module.css';

export type ExternalAnchorStylesNames = AnchorStylesNames | 'icon';
export type ExternalAnchorVariant = AnchorVariant;
export type ExternalAnchorCssVariables = AnchorCssVariables;

interface ExternalAnchorProps
    extends Omit<AnchorProps, 'styles' | 'classNames' | 'vars'>,
        StylesApiProps<ExternalAnchorFactory> {
    children?: ReactNode;
    /**
     * @default true
     */
    showIcon?: boolean;
}

export type ExternalAnchorFactory = PolymorphicFactory<{
    props: ExternalAnchorProps;
    defaultComponent: 'a';
    defaultRef: HTMLAnchorElement;
    stylesNames: ExternalAnchorStylesNames;
    vars: ExternalAnchorCssVariables;
    variant: ExternalAnchorVariant;
}>;

const defaultProps: Partial<ExternalAnchorProps> = {showIcon: true};

export const ExternalAnchor: ReturnType<typeof polymorphicFactory<ExternalAnchorFactory>> =
    polymorphicFactory<ExternalAnchorFactory>((props, ref) => {
        const {unstyled, children, showIcon, ...others} = useProps('ExternalAnchor', defaultProps, props);

        return (
            <Anchor unstyled={unstyled} ref={ref} {...others}>
                {children}
                {showIcon ? <ExternalSize16Px className={clsx({[classes.icon]: !unstyled})} height={16} /> : null}
            </Anchor>
        );
    });
