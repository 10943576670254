import {IReduxAction} from '@coveord/plasma-react';
import {UserRedux} from '../interfaces/UserInterface';

export interface UserReduxActionsPayload {
    user: UserRedux;
}

export const UserActions = {
    updateUser: 'UPDATE_USER',
};

export const updateUser = (user: UserRedux): IReduxAction<UserReduxActionsPayload> => ({
    type: UserActions.updateUser,
    payload: {user},
});
