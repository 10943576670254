import {NumberFormatter} from '@core/format';
import {DateRangePickerPreset, DateRangePickerValue} from '@coveord/plasma-mantine';
import dayjs, {Dayjs} from 'dayjs';
import {Locales} from '../strings/Locales';
import {LocalesKeys} from '../generated/LocalesKeys';

export type RangeUnit = dayjs.ManipulateType & dayjs.OpUnitType;

// Note: these values need to be compatible with dayjs.ManipulateType, dayjs.OpUnitType AND Intl.NumberFormat unit.
type SingularUnit = Extract<RangeUnit, 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year'>;
type PluralUnit = Extract<RangeUnit, 'seconds' | 'minutes' | 'hours' | 'days' | 'weeks' | 'months' | 'years'>;
export type PeriodPresetUnit = SingularUnit | PluralUnit;

const rePeriodPresetUnit = /^(second|minute|hour|day|week|month|year)s?$/;

const normalizeUnit = (unit: PeriodPresetUnit): SingularUnit => {
    const match = rePeriodPresetUnit.exec(unit);
    if (!match) {
        throw new RangeError(`Invalid unit: ${unit}`);
    }
    return match[1] as SingularUnit;
};

/**
 * (Internal) utility method that builds a label. It first looks if there's an "override" key,
 * otherwise it builds a generic label. Examples of such overrides are 'current.day' -> "Today",
 * and 'last.day' -> "Yesterday" (in English). The generic representation will be "Last (unit)".
 *
 * @param prefix The prefix of the label key, indicating where on the time line the range falls.
 * @param length The length of the range, in unit.
 * @param presetUnit The unit of the range.
 * @returns The label representing the range.
 */
const formatLabel = (prefix: 'last' | 'current' | 'next', length: number, presetUnit: PeriodPresetUnit): string => {
    let period: string;
    const unit = normalizeUnit(presetUnit);
    if (length === 1) {
        const key = `PeriodPreset.${prefix}.${unit}`;
        // Locales has no API to check if there is a key present, so we can only format and check if the value comes back as the key.
        const label = Locales.format(key as LocalesKeys);
        if (label !== key) {
            return label;
        }
        const parts = NumberFormatter.unitFull({unit}).formatToParts(1);
        period = parts.find((part) => part.type === 'unit')?.value ?? presetUnit;
    } else {
        period = NumberFormatter.unitFull({unit}).format(length);
    }
    return Locales.format(`PeriodPreset.${prefix}.generic`, {smart_count: length, period});
};

const rangeFrom = (
    startOffset: number,
    endOffset: number,
    unit: RangeUnit,
    reference: Dayjs = dayjs(),
): DateRangePickerValue => {
    const start = reference.add(startOffset, unit).startOf(unit).toDate();
    // If the endOffset is less than startOffset, default to an empty range (start === end).
    const end = endOffset < startOffset ? start : reference.add(endOffset, unit).endOf(unit).toDate();

    return [start, end];
};

const last = (length: number, unit: PeriodPresetUnit, reference: Dayjs = dayjs()): DateRangePickerPreset => {
    if (length < 1 || !Number.isInteger(length)) {
        throw new Error(`PeriodPreset.last requires an integer length of at least 1, got: ${length}`);
    }
    return Object.freeze({label: formatLabel('last', length, unit), range: rangeFrom(-length, -1, unit, reference)});
};

const current = (unit: PeriodPresetUnit, reference: Dayjs = dayjs()): DateRangePickerPreset =>
    Object.freeze({label: formatLabel('current', 1, unit), range: rangeFrom(0, 0, unit, reference)});

const next = (length: number, unit: PeriodPresetUnit, reference: Dayjs = dayjs()): DateRangePickerPreset => {
    if (length < 1 || !Number.isInteger(length)) {
        throw new Error(`PeriodPreset.next requires an integer length of at least 1, got: ${length}`);
    }
    return Object.freeze({label: formatLabel('next', length, unit), range: rangeFrom(1, length, unit, reference)});
};

// This method ensures the presets adhere to the expected type (record of DateRangePickerPreset), while still allowing to infer the keys.
const define = <T extends Record<string, DateRangePickerPreset>>(presets: T): Readonly<T> => Object.freeze<T>(presets);

/**
 * Utilities to define Date/Time picker presets.
 */
export const PeriodPreset = {
    /**
     * Determine a range from start and end offset, in the specified `unit`.
     * Note that offset `0` equals "current", e.g. for minute `0` is the current minute.
     * Positive offsets move forward in time (compared to `reference`) and conversely negative offsets move backward in time.
     *
     * @param startOffset The offset of the start, in `unit` and relative to `reference`.
     * @param endOffset The offset of the end, in `unit` and relative to `reference`.
     * @param unit The unit the offsets are in.
     * @param reference Optional reference timestamp to base relative calculations from, defaults to "now".
     * @returns The range as an an array in the form `[start, end]`, where both are `Date` values.
     */
    rangeFrom,

    /**
     * Generate a preset, including label, for the last "length units", e.g. the "last 24 hours" or "last year".
     * The range will end at the last full unit, e.g. last year will start at January 1st, and end at December 31st.
     * Note that this function always moves into the past, and `length` must be at least 1.
     * The unit `'week'` is locale dependant, it may select a different start-of-week day based on the selected
     * locale (provided culture metadata is loaded).
     * Change dayjs's default locale, or pass a `reference` in a different locale, to get this behavior.
     *
     * @param length The (positive >= 1) integer length of the range, which determines how far in the past the range starts.
     * @param unit The unit the range is in.
     * @param reference Optional reference timestamp to base relative calculations from, defaults to "now".
     * @returns A `DateRangePickerPreset` object with a label and `[start, end]` `Date` range.
     */
    last,

    /**
     * Generate a preset, including label, for the range of the current unit, e.g. the "this week" or "today".
     * The range will likely end in the future, e.g. this year will end at December 31st of the current year.
     * The unit `'week'` is locale dependant, it may select a different start-of-week day based on the selected
     * locale (provided culture metadata is loaded).
     * Change dayjs's default locale, or pass a `reference` in a different locale, to get this behavior.
     *
     * @param length The (positive >= 1) integer length of the range, which determines how far in the past the range starts.
     * @param unit The unit the range is in.
     * @param reference Optional reference timestamp to base relative calculations from, defaults to "now".
     * @returns A `DateRangePickerPreset` object with a label and `[start, end]` `Date` range.
     */
    current,

    /**
     * Generate a preset, including label, for the next "length units", e.g. the "tomorrow" or "next month".
     * The range will start at the next full unit, e.g. next year will start at January 1st, and end at December 31st.
     * Note that this function always moves into the future, and `length` must be at least 1.
     * The unit `'week'` is locale dependant, it may select a different start-of-week day based on the selected
     * locale (provided culture metadata is loaded).
     * Change dayjs's default locale, or pass a `reference` in a different locale, to get this behavior.
     *
     * @param length The (positive >= 1) integer length of the range, which determines how far in the past the range starts.
     * @param unit The unit the range is in.
     * @param reference Optional reference timestamp to base relative calculations from, defaults to "now".
     * @returns A `DateRangePickerPreset` object with a label and `[start, end]` `Date` range.
     */
    next,

    /**
     * Shortcut for last(1, 'day', reference), resolving to a "yesterday" range.
     * @param reference Optional reference timestamp to base relative calculations from, defaults to "now".
     * @returns A `DateRangePickerPreset` object representing yesterday.
     */
    yesterday: last.bind(null, 1, 'day') as (reference?: Dayjs) => DateRangePickerPreset,

    /**
     * Shortcut for current('day', reference), resolving to a "today" range.
     * @param reference Optional reference timestamp to base relative calculations from, defaults to "now".
     * @returns A `DateRangePickerPreset` object representing today.
     */
    today: current.bind(null, 'day') as (reference?: Dayjs) => DateRangePickerPreset,

    /**
     * Shortcut for next(1, 'day', reference), resolving to a "tomorrow" range.
     * @param reference Optional reference timestamp to base relative calculations from, defaults to "now".
     * @returns A `DateRangePickerPreset` object representing tomorrow.
     */
    tomorrow: next.bind(null, 1, 'day') as (reference?: Dayjs) => DateRangePickerPreset,

    /**
     * Utility method that allows defining an object of presets in a type-safe manner, while still inferring the keys automatically.
     *
     * @param presets The object that defines the presets; will be frozen using `Object.freeze`.
     * @returns The input `presets`, but frozen.
     */
    define,
};
