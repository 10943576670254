import {createSafeContext} from '@components/mantine';
import {PrivilegeModel} from '@core/api';
import {type PrivilegesTableContextType} from './PrivilegesTable.types';
import {noAccess} from './PrivilegesTableConstants';

export const [PrivilegesTableContextProvider, usePrivilegesTableContext] =
    createSafeContext<PrivilegesTableContextType>('PrivilegesTable component was not found in the tree');

export const usePrivilegeRow = (id: string) => {
    const {selectedPrivilegesByRowId, onChange, customPrivileges, setCustomPrivileges, ...others} =
        usePrivilegesTableContext();
    const scopedOnChange = (privileges: PrivilegeModel[]) => {
        onChange?.(
            Object.values({
                ...selectedPrivilegesByRowId,
                [id]: privileges,
            }).flat(),
        );
    };
    return {
        selectedPrivileges: selectedPrivilegesByRowId[id] || noAccess,
        customSelected:
            customPrivileges.includes(id) ||
            selectedPrivilegesByRowId[id]?.some(({targetId}) => !!targetId && targetId !== '*'),
        selectCustom: (selected: boolean) => {
            setCustomPrivileges((current) => (selected ? [...current, id] : current.filter((item) => item !== id)));
        },
        onChange: scopedOnChange,
        ...others,
    };
};
